import type { RoutePolicy } from '@/composables/policies/types'

const parseRoutePolicy = (routePolicy: RoutePolicy) => {
  const segments = routePolicy.split('.')

  return {
    policyName: segments[0],
    policyAction: segments[1],
  }
}

export default defineNuxtRouteMiddleware((to, from) => {
  const policies = usePolicies()

  const unauthorized = () => abortNavigation(createError({ statusCode: 403, statusMessage: 'Unauthorized' }))

  const { routePolicy } = to.meta

  if (routePolicy === false) {
    return
  }

  if (routePolicy === undefined) {
    console.error(`No policy defined for route ${to.fullPath}`)
    return unauthorized()
  }

  const { policyName, policyAction } = parseRoutePolicy(routePolicy)

  if (!(policyName in policies)) {
    console.error(`Policy ${policyName} not found`)
    return unauthorized()
  }

  // @ts-expect-error
  if (!(policyAction in policies[policyName])) {
    console.error(`Action ${policyAction} not found in ${policyName} policy`)
    return unauthorized()
  }

  // @ts-expect-error
  if (!policies[policyName][policyAction]()) {
    return unauthorized()
  }
})
